import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUnitsApi,
  addUnitApi,
  updateUnitApi,
  deleteUnitApi,
} from "../services/unitService";

// Fetch all units
export const fetchUnit = createAsyncThunk("unit/fetchUnit", async () => {
  const response = await fetchUnitsApi();
  console.log("Fetched units from API:", response);
  return response; // Ensure this returns a valid array
});

// Add a new unit
export const createUnit = createAsyncThunk("unit/createUnit", async (unitData) => {
  const response = await addUnitApi(unitData);
  console.log("Create unit API response:", response);
  return response; 
});

// Update an existing unit
export const updateUnit = createAsyncThunk(
  "unit/updateUnit",
  async ({ id, unit_type }) => {
    console.log("Sending data for update:", { id, unit_type });
    const response = await updateUnitApi(id, unit_type);
    console.log("Update unit API response:", response);
    return {
        id,
        unit_type,
      };
  }
  
);

// Delete a unit
export const deleteUnit = createAsyncThunk("unit/deleteUnit", async (id) => {
  const response = await deleteUnitApi(id);
  console.log("Delete API response:", response);
  return {
    id
  };
});

const unitSlice = createSlice({
  name: "unit",
  initialState: {
    unit: [], // Initial empty array
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch units
      .addCase(fetchUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("Fetched units payload:", action.payload);

        // Ensure payload is an array
        if (Array.isArray(action.payload)) {
          state.unit = action.payload;
        } else {
          console.error("Expected payload to be an array, received:", action.payload);
        }
      })
      .addCase(fetchUnit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create unit
      .addCase(createUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        
        console.log("Create unit response payload:", action.payload);
      
        // Ensure the payload is not empty and it's the expected array
        if (action.payload && action.payload.length > 0) {
          state.unit.push(action.payload[0]); // Only push the first unit into the state
        } else {
          console.error("Unexpected payload structure in createUnit response:", action.payload);
        }
      })
      
      .addCase(createUnit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Update unit
      .addCase(updateUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("Update payload:", action.payload);
        console.log("Current state.unit:", state.unit);
      
        const index = state.unit.findIndex((unit) => unit.id === action.payload.id);
        console.log("Found index:", index);
      
        if (index !== -1) {
          console.log("Updating unit type...");
          state.unit[index].unit_type = action.payload.unit_type;
          console.log("Updated unit:", state.unit[index]);
        } else {
          console.error("Unit not found to update, adding new unit...");
          state.unit.push(action.payload);
        }
      })
      
      .addCase(updateUnit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Delete unit
      .addCase(deleteUnit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUnit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.unit = state.unit.filter((unit) => unit.id !== action.payload.id);
        console.log("Remaining units after delete:", state.unit);
      })
      .addCase(deleteUnit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default unitSlice.reducer;
