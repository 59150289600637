import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextInputForm } from '../../components/Forms';

const UnitCreations = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg="12" className="py-3">
            <TextInputForm
              textlabel="Unit"
              name="unit_type"
              value={formData.unit_type || ""}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UnitCreations;

