import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import PageTitle from "../components/PageTitle";
import { ActionButton, Buttons } from "../components/Buttons";
import { DropDown, TextInputForm } from "../components/Forms";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/TableUI";
import { MdOutlineDelete } from "react-icons/md";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { LiaEditSolid } from "react-icons/lia";
import { fetchInvoice, deleteInvoice } from "../slices/invoiceSlice";
import NotifyData from "../components/NotifyData";
import Pagnation from "../components/Pagnation";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdOutlineFilterList } from "react-icons/md";
import { fetchCustomer } from "../slices/customerSlice";

const Invoice = () => {
  const dispatch = useDispatch();
  const { invoice } = useSelector((state) => state.invoice); // Fetch invoice state from Redux
  const { customer } = useSelector((state) => state.customer); // Fetch customer state from Redux
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [selectedCustomer, setSelectedCustomer] = useState(""); // Selected customer for filtering
  const [show, setShow] = useState(false); // Offcanvas visibility
  const navigate = useNavigate();

  // Fetch data on component mount
  useEffect(() => {
    dispatch(fetchInvoice());
    dispatch(fetchCustomer());
  }, [dispatch]);

  const handleClose = () =>{
     setShow(false);
     setSelectedCustomer("");
  };
  const handleShow = () => setShow(true);

  // Handle delete functionality
  const handleDelete = async (id) => {
    try {
      await dispatch(deleteInvoice(id)).unwrap();
      NotifyData("Invoice Deleted Successfully", "success");
    } catch (error) {
      console.error("Error Deleting Invoice", error);
      NotifyData("Invoice Deletion Failed", "error");
    }
  };

  // Handle edit functionality
  const handleEdit = (invoice) => {
    navigate("/create-invoice", { state: { invoice } });
   
  };

  const handleprint = (invoice) => {
    navigate("/billpreview", {state : {invoice}});
  }

  // Filter invoices based on search term and selected customer
  const filteredInvoices =
  invoice?.filter((item) => {
    const customerName = item.customer_name || ""; // Default to empty string
    const matchesSearchTerm = customerName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesSelectedCustomer =
      !selectedCustomer || customerName === selectedCustomer;
    return matchesSearchTerm && matchesSelectedCustomer;
  }) || [];



  // Get unique customer names for the dropdown
  const customerNames = Array.from(
    new Set(customer?.map((c) => c.customer_name))
  );

  // Table headers
  const InvoiceHead = ["No", "Invoice No", "Customer Name", "Mobile Number", "Total"];

  // Map filtered data into rows for `TableUI`
  const InvoiceData = filteredInvoices.map((item, index) => ({
    values: [
      index + 1,
      item.invoice_no,
      item.customer_name,
      item.mobile_number,
      `Rs.${item.grand_total}`,
      <ActionButton
        options={[
          {
            label: "Print Invoice",
            icon: <LiaEditSolid />,
            onClick: () => handleprint(item),
          },
          {
            label: "Edit",
            icon: <LiaEditSolid />,
            onClick: () => handleEdit(item),
          },
          {
            label: "Delete",
            icon: <MdOutlineDelete />,
            onClick: () => handleDelete(item.id),
          },
        ]}
        label={<HiOutlineDotsVertical />}
      />,
    ],
  }));

  const handleNavigate = () => {
    navigate("/create-invoice");
  };

  return (
    <div id="main">
      <Container fluid>
        <Row>
          {/* Page Title */}
          <Col lg="6" className="py-3">
            <PageTitle PageTitle="Invoices" showButton={false} />
          </Col>
          {/* Button to Add Invoice */}
          <Col lg="6" className="py-3 text-end">
            <Buttons
              label={<>Add Invoice</>}
              classname="crud-btn"
              OnClick={handleNavigate}
            />
          </Col>
          {/* Search Section */}
          <Col lg="3" className="align-self-center">
            <TextInputForm
              prefix_icon={<FaMagnifyingGlass />}
              PlaceHolder="Search by Customer Name"
              className="form-control-padleft"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Handle dynamic search
            />
          </Col>
          <Col lg="9" md="12" xs="12" className="py-3 text-end">
            <Buttons
              label={
                <>
                  <MdOutlineFilterList /> Filters
                </>
              }
              classname="crud-btn"
              OnClick={handleShow}
            />
          </Col>
          {/* Render Invoice Table */}
          <Col lg="12" xs="12">
            <TableUI headers={InvoiceHead} body={InvoiceData} className="table-end" />
          </Col>
          {/* Pagination UI */}
          <Col lg="12" xs="12">
            <Pagnation />
          </Col>
        </Row>
      </Container>
      {/* Filter Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Row>
              <Col xs="12">
                <DropDown
                  textlabel="Customer Name"
                  options={customerNames.map((name) => ({
                    label: name,
                    value: name,
                  }))}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  value={selectedCustomer}
                />
              </Col>
              <Col xs="12" className="py-3">
                <Buttons
                  label="Undo Filter"
                  classname="crud-btn"
                  OnClick={handleClose}
                />
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Invoice;
