import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCompanyApi,updateCompanyApi } from '../services/companyService';

// Async thunk for fetching companies
export const fetchCompanies = createAsyncThunk(
  'company/fetchCompanies',
  async (searchText = "", { rejectWithValue }) => {
    try {
      const response = await fetchCompanyApi(searchText);
      console.log('Fetched companies from API:', response);

      return response?.data || []; // Ensure it returns an empty array if no data is received
    } catch (error) {
      console.error('Error fetching companies:', error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
//Async thunk for update companies
export const updateCompanyThunk = createAsyncThunk(
    'company/updateCompany',
    async (companyData, { rejectWithValue }) => {
      try {
        const response = await updateCompanyApi(companyData);
        console.log('Updated company response from server:', response);
        return response;
      } catch (error) {
        console.error("Error during company update:", error);
        return rejectWithValue(error.message);
      }
    }
  );
// Initial state for the slice
const initialState = {
  companies: [], // Ensure this is initialized as an empty array, not null
  status: 'idle',
  error: null,
};

// Create slice
const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // Reducer to update company data
    updateCompany: (state, action) => {
      const { company_id, data } = action.payload;
      console.log('Action payload received for update:', action.payload);
      console.log('Current companies state:', state.companies);

      if (!company_id) {
        console.error('Invalid company_id provided.');
        return;
      }

      const index = state.companies.findIndex(
        (company) => company.company_id === company_id
      );

      if (index !== -1) {
        state.companies[index] = {
          ...state.companies[index],
          ...data,
        };
        console.log('Updated company at index:', index);
      } else {
        console.warn('Company ID not found:', company_id);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('Loaded companies into state:', action.payload);
        state.companies = action.payload; // Ensure this is properly set
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        console.error('Error during fetch:', action.payload);
      });
  },
});

// Export actions and reducer
export const { updateCompany } = companySlice.actions;
export default companySlice.reducer;
