import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import { ActionButton, Buttons } from '../components/Buttons';
import { TextInputForm } from '../components/Forms';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import TableUI from '../components/TableUI';

import { MdOutlineDelete } from 'react-icons/md';
import { LiaEditSolid } from 'react-icons/lia';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { fetchCustomer, updateCustomer, deleteCustomer } from '../slices/customerSlice';
import NotifyData from "../components/NotifyData";
import Pagnation from '../components/Pagnation';
import { useNavigate } from "react-router-dom";

const Customers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customer } = useSelector((state) => state.customer);

  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    dispatch(fetchCustomer());
  }, [dispatch]);

  const handleEdit = (customer) => {
    navigate("/create-customer", { state: { customer } });
  };
  const handleDelete = async (id) => {
    try {
      await dispatch(deleteCustomer(id)).unwrap();
      NotifyData("Customer Deleted Successfully", "success");
    } catch (error) {
      NotifyData("Customer Deletion Failed", "error");
    }
  };

  const filteredCustomers = customer?.filter((item) =>
    item?.customer_name?.toLowerCase().includes(searchTerm.toLowerCase()) || false
  ) || [];
  

  const CustomerHead = ['No', 'Name', 'State', 'City'];
  const CustomerData = filteredCustomers.map((item, index) => ({
    values: [
      index + 1,
      item.customer_name,
      item.state,
      item.city,
      <ActionButton
        options={[
          { label: 'Edit', icon: <LiaEditSolid />, onClick: () => handleEdit(item) },
          { label: 'Delete', icon: <MdOutlineDelete />, onClick: () => handleDelete(item.id) },
        ]}
        label={<HiOutlineDotsVertical />}
      />,
    ],
  }));

  return (
    <div id="main">
      <Container fluid>
        <Row>
        <Col lg="12" md="12" xs="12" className='py-3 align-self-center'>
            <PageTitle PageTitle="Customers" showButton={false} />
          </Col>
          
          <Col lg="3" className="align-self-center">
            <TextInputForm
              prefix_icon={<FaMagnifyingGlass />}
              PlaceHolder="Search Customer Name"
              className="form-control-padleft"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col lg="12" xs="12">
            <TableUI headers={CustomerHead} body={CustomerData} className="table-end" />
          </Col>
          <Col lg="12" xs="12">
            <Pagnation />
          </Col>
        </Row>
      </Container>
      
    </div>
  );
};

export default Customers;

