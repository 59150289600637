
import React from 'react';
import { Space, TimePicker } from 'antd';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
const TextInputForm = ({
  textlabel,
  PlaceHolder,
  value,
  name,
  onKeyPress,
  className,
  onChange,
  readOnly,
  suffix_icon,
  prefix_icon,
  type,
  Checked,
  isCheckbox, 
}) => {
  return (
    <>
      {textlabel && <Form.Label className='px-2 regular'>{textlabel}</Form.Label>}
      <div className="input-container">
        {prefix_icon && <span className="prefix-icon">{prefix_icon}</span>}
        {isCheckbox ? (
          <Form.Check 
            type="checkbox" 
            checked={Checked} 
            name={name} 
            onChange={onChange} 
            disabled={readOnly}
            label={PlaceHolder} // You can use placeholder as label for checkbox
          />
        ) : (
          <Form.Control
            type={type}
            placeholder={PlaceHolder}
            value={value}
            name={name}
            className={className}
            onChange={onChange}
            onKeyPress={onKeyPress}
            readOnly={readOnly}
            disabled={readOnly}
          />
        )}
        {suffix_icon && <span className="suffix-icon">{suffix_icon}</span>}
      </div>
    </>
  );
};

const TextArea = ({
  textlabel,
  PlaceHolder,
  value,
  name,
  onKeyPress,
  className,
  onChange,
  Row
}) => {
  return (
    <div>
      {textlabel && (
        <div>
          <Form.Label className='px-2 regular'>{textlabel}</Form.Label>
        </div>
      )}
      <Form.Control
        as="textarea"
        rows={Row}
        placeholder={PlaceHolder}
        className={className} 
        value={value} 
        onChange={onChange} 
        name={name}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};


const DropDown = ({ textlabel, placeholder, value, onChange, name, options = [] }) => {
  const handleChange = (selectedOption) => {
    onChange({
      target: {
        name,
        value: selectedOption.value,
      },
    });
  };

  return (
    <>
      {textlabel && <Form.Label className='px-2 regular'>{textlabel}</Form.Label>}
      <Select
        options={options}
        placeholder={placeholder}
        value={options.find(option => option.value === value)}
        onChange={handleChange}
      />
    </>
  );
};

export default DropDown;


const DatePick=({textlabel,
  placeholder,
  value,
  name,
  onKeyPress,
  onChange})=>{
  return(
   <div>
      <div>{textlabel && <Form.Label className='px-2 regular'>{textlabel}</Form.Label>}</div>
      <DatePicker className='form-control w-100' placeholderText='dd/mm/yyyy'/>
   </div>
  )
};

const TimePick = ({textlabel}) => {
  return (
    <>
      <Space direction="vertical">
      <div>{textlabel && <Form.Label className='px-2 regular'>{textlabel}</Form.Label>}</div>
          <TimePicker.RangePicker/>
      </Space>
    </>
  );
};

const CheckBox=({textlabel,OnChange,boxlabel})=>{
  return(
    <div>
      <div>{textlabel && <Form.Label className='px-2 regular'>{textlabel}</Form.Label>}</div>
      <div className='check-box'>
        <div className='tick-box'>
            <TextInputForm 
                type="checkbox" 
                onChange={OnChange}  
                isCheckbox={true}
            />
            <span className='mx-3'>{boxlabel}</span>
        </div>
      </div>
    </div>
  )
}
export { TextInputForm,TextArea,DropDown,DatePick,TimePick,CheckBox };
