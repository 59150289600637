import axiosInstance from "../config/API";

const API_ENDPOINT = '/company.php';

// Fetch companies from API
export const fetchCompanyApi = async (searchText = "") => {
  try {
    const payload = {
        search_text: searchText, // Ensure this is always sent
      };
    const response = await axiosInstance.post(API_ENDPOINT,payload);
    
    return response.data; // Adjust based on API response
  } catch (error) {
   
    throw error;
  }
};

export const updateCompanyApi = async (companyData) => {
    try {
      const payload = {
        company_id: companyData.company_id,
        company_name: companyData.company_name,
        mobile_number: companyData.mobile_number,
        gst_no: companyData.gst_no,
        address: companyData.address,
      };
  
      const response = await axiosInstance.post(API_ENDPOINT, payload);
      return response.data; 
    } catch (error) {
      console.error("Error in updating company details:", error);
      throw error;
    }
  };
