import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
 fetchCustomerApi,
 updateCustomerApi,
 deleteCustomerApi,
} from "../services/customerService";

// Fetch all users
export const fetchCustomer = createAsyncThunk("customer/fetchCustomer", async () => {
  const response = await fetchCustomerApi();
  console.log('response',response);
  return response;
});

// Update user by ID
export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async ({ id,customerdetails }) => {
    console.log("siva",{ id,customerdetails });
    const response = await updateCustomerApi({ id : id , customerdetails : customerdetails});
    return {
        id, customerdetails
    };
  }
);

// Delete user by ID
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (Id) => {
    const response = await deleteCustomerApi(Id);
    return {
        Id
      };
  }
);
const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.customer = action.payload;
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(action.payload)
        state.customer = state.customer.filter((customer) => customer.id !== action.payload.Id);
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.customer.findIndex(user => user.id === action.payload.id);
        console.log(index);
        if (index !== -1) {
          // Replace the updated user with the response payload
          state.customer[index] = action.payload;
        }
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default customerSlice.reducer;
