import axiosInstance from "../config/API";
const API_ENDPOINT = '/unit.php';

// Fetch all Units
export const fetchUnitsApi = async () => {
    const payload = {
        action : "listUnit",
      };
  const response = await axiosInstance.post(API_ENDPOINT,payload);
 
  return response.data.body.units;
};

// Fetch a single Unit by ID
export const fetchUnitByIdApi = async (id) => {
  const response = await axiosInstance.get(`${API_ENDPOINT}/${id}`);
  return response.data.body.data;  // Corrected response structure
};

// Add a new Unit
export const addUnitApi = async (UnitData) => {
    const payload = {
        action : "addUnit",
        unit_type : UnitData.unit_type
        };
  const response = await axiosInstance.post(API_ENDPOINT, payload);
  console.log("add Unit :" ,response.data)
  return response.data.body.units;  
};

// Update a Unit by ID
export const updateUnitApi = async (id, unit_type) => {
    const payload ={
        action : "updateUnit",
        edit_unit_id : id,
        unit_type : unit_type
    }
  const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
  console.log('response',response);
  return response.data.head.id;  // Corrected response structure
};

// Delete a Unit by ID
export const deleteUnitApi = async (id) => {
    const payload = {
        action : "deleteUnit",
        delete_unit_id : id
        }
  const response = await axiosInstance.post(`${API_ENDPOINT}`,payload);
  return id;
};
