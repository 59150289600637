import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/UserSlice';
import authReducer from '../slices/authSlice';
import LoginMiddleware from '../middleware/LoginMiddleware';
import roleReducer from '../slices/roleSlice'; // Fixed path
import companyReducer from '../slices/companySlice'; // Fixed path
import  unitReducer from '../slices/unitSlice';
import categoryReducer from '../slices/categorySlice';
import productReducer from '../slices/productSlice';
import invoiceReducer from '../slices/invoiceSlice';
import customerReducer from '../slices/customerSlice';

const store = configureStore({
    reducer: {
        users: userReducer,
        auth: authReducer,
        roles: roleReducer,
        company: companyReducer,
        unit: unitReducer,
        category : categoryReducer,
        product : productReducer,
        invoice : invoiceReducer,
        customer : customerReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(LoginMiddleware),
});

export default store;
