import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import { TextInputForm } from '../../components/Forms';
import Footer from '../../components/Footer';
import Dialog from '../../components/Dialog';
import { updateCustomer } from '../../slices/customerSlice';
import { useDispatch } from 'react-redux';
import  NotifyData  from '../../components/NotifyData';

const CustomersCreations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();
  const { customer } = state || {};


  const [customerDetails, setCustomerDetails] = useState({
  });
  useEffect(() => {
    if (customer) {
      // Group customer-related fields into customerDetails
      const customerDetails = {
        customer_name: customer.customer_name || "",
        state: customer.state || "",
        city: customer.city || "",
        mobile_number: customer.mobile_number || "",
        reference_number: customer.reference_number || "",
        agent_name: customer.agent_name || "",
        transport_name: customer.transport_name || "",
      };

      setCustomerDetails(customerDetails);  
    }
  }, [customer]);
  console.log(customerDetails);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleInputChange = (field, value) => {
    setCustomerDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    if (!customerDetails.customer_name) {
      NotifyData("Customer Name is required!", "error");
      return;
    }

    console.log({customer,customerDetails});

    const id = customer?.id;

    // Dispatch the update action with the updated details
    dispatch(updateCustomer({id,customerdetails : customerDetails}))
      .unwrap()
      .then(() => {
        NotifyData("Customer updated successfully!", "success");
        navigate(-1); // Go back to the previous page
      })
      .catch((error) => {
        NotifyData("Failed to update customer. Please try again!", "error");
        console.error(error);
      });
  };

  const handleCloseForm = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmClose = (confirm) => {
    setShowConfirmDialog(false);
    if (confirm) {
      navigate(-1);
    }
  };

  return (
    <div id="main">
      <Container fluid>
        <Row>
          <Col lg="12">
            <PageTitle PageTitle="Update Customer" showButton={true} OnClick={handleCloseForm} />
          </Col>
          <Col lg="12" className="py-3">
            <div className="content-box">
              <Row>
                {[
                  { label: "Customer Name", value: "customer_name" },
                  { label: "State", value: "state" },
                  { label: "City", value: "city" },
                  { label: "Mobile Number", value: "mobile_number" },
                  { label: "Reference Number", value: "reference_number" },
                  { label: "Agent Name", value: "agent_name" },
                  { label: "Transport Name", value: "transport_name" },
                ].map((item, index) => (
                  <Col lg="4" md="6" xs="12" className="py-3" key={index}>
                    <TextInputForm
                      textlabel={item.label}
                      value={customerDetails[item.value] || ""}
                      onChange={(e) =>
                        handleInputChange(item.value, e.target.value)
                      }
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col lg="12">
            <Footer
              LabelOne={
                <>
                  Update
                </>
              }
              LabelTwo={
                <>
                  Cancel
                </>
              }
              LabelOneClick={handleSave}
              LabelTwoClick={handleCloseForm}
            />
          </Col>
        </Row>
      </Container>
      <Dialog
        isVisible={showConfirmDialog}
        onConfirm={handleConfirmClose}
        onCancel={() => handleConfirmClose(false)}
      />
    </div>
  );
};

export default CustomersCreations;
