import React from "react";
import { useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import Bill from "./Bill";

const Billpreview = () => {
  const location = useLocation();
  const { invoice } = location.state || {}; // Retrieve invoice data from state
  if (!invoice) {
    return <div>Error: No invoice data available.</div>;
  }
  return (
    <PDFViewer width="100%" height="1000">
      <Bill invoice={invoice} />
    </PDFViewer>
  );
};

export default Billpreview;
