import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
 fetchProductApi,
 addProductApi,
 updateProductApi,
 deleteProductApi
} from "../services/productService";

// Fetch all users
export const fetchproduct = createAsyncThunk("product/fetchProduct", async () => {
  const response = await fetchProductApi();
  console.log('response',response);
  return response;
});


// Add new user
export const addProduct = createAsyncThunk("product/addProduct", async (productData) => {
  console.log("productData:" ,productData)
  const response = await addProductApi(productData);
  console.log("API Response on Add Product:", response);
  return response;
});

// Update user by ID
export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ id, Category_type,product_name,Unit_type,SubUnit_type,Count,Sub_count,unit_price }) => {
    console.log("siva",{ id, Category_type,product_name,Unit_type,SubUnit_type,Count,Sub_count,unit_price });
    const response = await updateProductApi({ id : id , Category_type : Category_type,product_name : product_name,Unit_type :Unit_type,SubUnit_type :SubUnit_type,Count : Count,Sub_count : Sub_count,unit_price :unit_price});
    return {
        id, Category_type,product_name,Unit_type,SubUnit_type,Count,Sub_count,unit_price
    };
  }
);

// Delete user by ID
export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (productId) => {
    const response = await deleteProductApi(productId);
    return response;
  }
);
const productSlice = createSlice({
  name: "product",
  initialState: {
    product: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchproduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchproduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.product = action.payload;
      })
      .addCase(fetchproduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("action.payload:", action.payload);
        state.product.push(action.payload[0]);  // Add the user to the users array
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.product = state.product.filter((product) => product.id !== action.payload);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.product.findIndex(product => product.id === action.payload.id);
       
        console.log('index',index);
        if (index !== -1) {

          state.product[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default productSlice.reducer;
