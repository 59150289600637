import axios from 'axios';
const API_URL = "https://gurulakshmifireworks.zentexus.com/api";
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });


export default axiosInstance;