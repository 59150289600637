import axiosInstance from "../config/API";
const API_ENDPOINT = '/category.php';

// Fetch all Units
export const fetchCategoryApi = async () => {
    const payload = {
        action : "listCategory",
      };
  const response = await axiosInstance.post(API_ENDPOINT,payload);
 
  return response.data.data;
};

// // Fetch a single Unit by ID
// export const fetchUnitByIdApi = async (id) => {
//   const response = await axiosInstance.get(`${API_ENDPOINT}/${id}`);
//   return response.data.body.data;  // Corrected response structure
// };

// Add a new Unit
export const addCategoryApi = async (CategoryData) => {
    const payload = {
        action : "createCategory",
        Category_type : CategoryData.Category_type
        };
  const response = await axiosInstance.post(API_ENDPOINT, payload);
  console.log("add Category :" ,response.data)
  return response.data.head.data;  
};

// Update a Unit by ID
export const updateCategoryApi = async (id, Category_type) => {
    const payload ={
        action : "updateCategory",
        edit_Category_id : id,
        Category_type : Category_type
    }
  const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
  console.log('response',response);
  return response.data.head.id;  // Corrected response structure
};

// Delete a Unit by ID
export const deleteCategoryApi = async (id) => {
    const payload = {
        action : "deleteCategory",
        delete_Category_id : id
        }
  const response = await axiosInstance.post(`${API_ENDPOINT}`,payload);
  return id;
};
