import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../components/PageTitle';
import TableUI from '../components/TableUI';
import { ActionButton } from '../components/Buttons';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { TbCircleLetterI } from "react-icons/tb";
import CustomModal from '../components/Modal';
import CompanyCreation from './creations/CompanyCreation';
import { fetchCompanies, updateCompany } from '../slices/companySlice';
import NotifyData from "../components/NotifyData"
import { updateCompanyThunk } from '../slices/companySlice';

const DailyLogins = () => {
  const dispatch = useDispatch();

  // Redux State
  const companies = useSelector((state) => state.company.companies);
  const status = useSelector((state) => state.company.status);
  const error = useSelector((state) => state.company.error);

  // Local State
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
 console.log(formData);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  // Fetch companies on mount
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCompanies());
    }
  }, [dispatch, status]);

  const handleOpen = (company) => {
   
    setFormData({
      company_name: company.company_name,
      gst_no: company.gst_no,
      mobile_number: company.mobile_number,
      address: company.address,
    });
    setSelectedCompanyId(company.company_id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setFormData({});
    setSelectedCompanyId(null);
  };



const handleUpdate = () => {
  if (selectedCompanyId) {
    dispatch(updateCompany({ company_id: selectedCompanyId, data: formData }));
    setShow(false);
  }
  if (selectedCompanyId) {
    dispatch(
      updateCompanyThunk({
        company_id: selectedCompanyId,
        company_name: formData.company_name,
        mobile_number: formData.mobile_number,
        gst_no: formData.gst_no,
        address: formData.address,
      })
    )
      .unwrap() 
      .then((response) => {
        console.log("Update successful:", response);
        NotifyData("Company Updated Success", "success");
        setShow(false); 
      })
      .catch((error) => {
        console.error("Update failed:", error);
        NotifyData("Failed to update company", "error");
      });
  } else {
    NotifyData("No company selected for update", "warning");
  }
};


  const CompanyHead = ["Company Name", "Mobile Number", "Action"];
  const CompanyData = companies.map((company) => ({
    values: [
      company.company_name || "N/A",
      company.mobile_number || "N/A",
      <ActionButton
        key={company.company_id}
        options={[
          {
            label: 'Edit',
            icon: <TbCircleLetterI />,
            onClick: () => handleOpen(company),
          },
        ]}
        label={<HiOutlineDotsVertical />}
      />,
    ],
  }));

  return (
    <div id="main">
      <Container fluid>
        <Row>
          <Col lg="6" md="12" xs="12" className="py-3 align-self-center">
            <PageTitle PageTitle="Company" showButton={false} />
          </Col>
          <Col lg="12" xs="12">
            {status === 'loading' && <div>Loading...</div>}
            {status === 'failed' && <div>Error: {error}</div>}
            {status === 'succeeded' && (
              <TableUI
                headers={CompanyHead}
                body={CompanyData}
                showActionColumn={false}
                className="table-end"
              />
            )}
          </Col>
        </Row>
      </Container>

      <CustomModal
        show={show}
        setShow={setShow}
        pageTitle={<>Company</>}
        showButton={true}
        submitButton={true}
        label={<>Update</>}
        CancelLabel={<>Cancel</>}
        BodyComponent={
          <CompanyCreation formData={formData} setFormData={setFormData} />
        }
        OnClick={handleUpdate}
        Size="md"
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default DailyLogins;
