import axiosInstance from "../config/API";
const API_ENDPOINT = '/customer.php';

// Fetch all users
export const fetchCustomerApi = async () => {
    const payload = {
        action: "listCustomers",
    }
    const response = await axiosInstance.post(API_ENDPOINT, payload);
    console.log("fetch customer :", response.data.body.customers)
    return response.data.body.customers;
};


// Update a user by ID
export const updateCustomerApi = async (customerData) => {
    console.log("customerData received:", customerData);
  
    // Ensure customerData and customerdetails exist
    if (!customerData?.customerdetails) {
      throw new Error("Customer details are missing or invalid");
    }
  
    const payload = {
      action: "updateCustomer",
      id: customerData.id,
      customer_name: customerData.customerdetails.customer_name || "",
      state: customerData.customerdetails.state || "",
      city: customerData.customerdetails.city || "",
      mobile_number: customerData.customerdetails.mobile_number || "",
      reference_number: customerData.customerdetails.reference_number || "",
      agent_name: customerData.customerdetails.agent_name || "",
      transport_name: customerData.customerdetails.transport_name || "",
    };
  
    console.log("payload being sent:", payload);
  
    const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
    console.log("update response", response.data);
  
    return response.data.head?.id || null;
  };
  
  

// Delete a user by ID
export const deleteCustomerApi = async (id) => {
    const payload = {
        action: "deleteCustomer",
        id: id
    }
    const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
    return id;  // Return the user ID for successful deletion
};
