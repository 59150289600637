import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextArea, TextInputForm } from '../../components/Forms';

const CompanyCreation = ({ formData, setFormData }) => {
    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target; // Dynamically handle changes
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <Container>
                <Row>
                    <Col xs="12" className="py-3">
                        <TextInputForm
                            name="company_name"
                            textlabel="Company Name"
                            value={formData.company_name || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col xs="12" className="py-3">
                        <TextInputForm
                            name="gst_no"
                            textlabel="GST No."
                            value={formData.gst_no || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col xs="12" className="py-3">
                        <TextInputForm
                            name="mobile_number"
                            textlabel="Mobile Number"
                            value={formData.mobile_number || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col xs="12" className="py-3">
                        <TextArea
                            name="address"
                            textlabel="Address"
                            Row={4}
                            value={formData?.address || ''} // Defensive check to ensure it doesn't throw
                            onChange={handleChange}
                        />
                    </Col>

                </Row>
            </Container>
        </>
    );
};

export default CompanyCreation;
