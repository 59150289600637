import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import { ActionButton, Buttons } from '../../components/Buttons';
import { TextInputForm } from '../../components/Forms';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import TableUI from '../../components/TableUI';
import CustomModal from '../../components/Modal';
import { MdOutlineDelete } from 'react-icons/md';
import { TbCircleLetterI } from 'react-icons/tb';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import UnitCreations from './UnitCreation';
import { fetchUnit, createUnit, updateUnit,deleteUnit } from '../../slices/unitSlice';
import NotifyData from "../../components/NotifyData";
import Pagnation from '../../components/Pagnation'
const Unit = () => {
  const dispatch = useDispatch();
  const { unit } = useSelector((state) => state.unit);

  console.log(unit);

  const [formData, setFormData] = useState({});
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  console.log(formData);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(fetchUnit());
  }, [dispatch]);

  const handleEdit = (unit) => {
    setEditMode(true);
    setSelectedUnit(unit);
    setFormData({ unit_type: unit.unit_type });
    handleOpen();
  };
  
  const handleCreate = () => {
    setEditMode(false);
    setFormData({});
    handleOpen();
  };

  const handleSubmit = async () => {
    console.log("EditMode:", editMode);
    console.log("Selected Unit ID:", selectedUnit?.id);
    console.log("Form Data being dispatched:", formData);
  
    if (editMode) {
      if (!selectedUnit?.id) {
        console.error("Error: No selected unit ID available.");
        return;
      }
  
      try {
        await dispatch(updateUnit({ id: selectedUnit.id, unit_type: formData.unit_type })).unwrap();
        NotifyData("Unit Updated Success", "success");
      } catch (error) {
        console.error("Error updating unit:", error);
        NotifyData("Unit Update Failed", "error");
      }
    } else {
      try {
        await dispatch(createUnit(formData)).unwrap();
        NotifyData("Unit Created Successfully", "success");
      } catch (error) {
        console.error("Error creating unit:", error);
        NotifyData("Unit Creation Failed", "error");
      }
    }
  
    handleClose();
    setFormData({});
    setEditMode(false);
  };
  
  
  const handleDelete = (id)=>{
    dispatch(deleteUnit(id)).unwrap();
    NotifyData("Unit Deleted Successfully", "success");

  }
  const filteredUnits = unit?.filter((item) =>
    item.unit_type.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];
  
  const RoleHead = ['No', 'Unit'];
  const RoleData =
  filteredUnits?.length > 0
    ? filteredUnits.map((item, index) => ({
        values: [
          index + 1,
          item.unit_type, // Ensure `item.unit_type` exists
          <ActionButton
            options={[
              { label: 'Edit', icon: <TbCircleLetterI />, onClick: () => handleEdit(item) },
              { label: 'Delete', icon: <MdOutlineDelete />, onClick: () => handleDelete(item.id) },
            ]}
            label={<HiOutlineDotsVertical />}
          />,
        ],
      }))
    : [];


  return (
    <div id="main">
      <Container fluid>
        <Row>
          <Col lg="6" className="py-3">
            <PageTitle PageTitle="Unit" showButton={false} />
          </Col>
          <Col lg="6" className="py-3 text-end">
            <Buttons label="Create New" classname="crud-btn" OnClick={handleCreate} />
          </Col>
          <Col lg="3" className="align-self-center">
            {/* Search input here */}
            <TextInputForm
              prefix_icon={<FaMagnifyingGlass />}
              PlaceHolder="Search Unit Name"
              className="form-control-padleft"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term dynamically
            />
          </Col>
          <Col lg="12" xs="12">
            <TableUI headers={RoleHead} body={RoleData} className="table-end" />
          </Col>
          <Col lg="12" xs="12">
            <Pagnation/>
          </Col>
        </Row>
      </Container>
      <CustomModal
        show={show}
        setShow={setShow}
        pageTitle={editMode ? <>Edit Unit</> : <>Create Unit</>}
        showButton={true}
        submitButton={true}
        label={editMode ? <>Update</> : <>Submit</>}
        CancelLabel={<>Cancel</>}
        BodyComponent={<UnitCreations formData={formData} setFormData={setFormData} />}
        OnClick={handleSubmit}
        Size="md"
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Unit;
