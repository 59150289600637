import React, { useState,useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { MdLogout } from "react-icons/md";
import { ActionButton, Buttons } from "../Buttons";
import { RiMenu3Fill } from "react-icons/ri";
import { HiOutlineUser } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import Dialog from "../Dialog";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../services/LoginService";
const Header = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [username, setUsername] = useState("");
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);
  const handleClose = () => {
    setShowConfirmDialog(true);
  };
  const handleLogout = (confirm) => {
    setShowConfirmDialog(false);
    if (confirm) {
      dispatch(logoutUser())
        .then(() => {
          console.log("Logged out successfully");
          Navigate("/");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
          alert(error);
        });
      console.log("logout Success");
    }
  };
  const options = [
    { label: "Logout", icon: <MdLogout size={20} />, onClick: handleClose },
  ];
  const handleSideBar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };

  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary pos-fixed top-navbar">
        <Container className="head-pad">
          <Buttons
            classname="icon-only"
            label={
              <>
                <RiMenu3Fill />
              </>
            }
            OnClick={handleSideBar}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="profile">
                <ActionButton
                  label={
                    <>
                      <span className="mx-1">
                        <HiOutlineUser size={22} />
                      </span>
                      <span className="mx-1">{username || "Guest"}</span>
                    </>
                  }
                  options={options}
                />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <>
        <Dialog
          DialogTitle="Are you sure you want to logout?"
          isVisible={showConfirmDialog}
          onConfirm={handleLogout}
          onCancel={() => handleLogout(false)}
        />
      </>
    </>
  );
};

export default Header;
