import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TextInputForm } from "../../components/Forms";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

const UserCreation = ({ formData, setFormData, schema }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <Container>
        <Row>
          {schema.map((field, index) => (
            <Col lg="12" md="12" xs="12" className="py-3" key={index}>
              <TextInputForm
                textlabel={field.label}
                name={field.name}
                value={formData[field.name] || ""}
                type={
                  field.type === "password" && showPassword
                    ? "text"
                    : field.type
                } // Toggle password visibility
                onChange={handleChange}
                classname={field.classname || ""}
                suffix_icon={
                  field.type === "password" ? (
                    showPassword ? (
                      <VscEyeClosed onClick={togglePasswordVisibility} />
                    ) : (
                      <VscEye onClick={togglePasswordVisibility} />
                    )
                  ) : null
                }
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default UserCreation;
