import React from 'react';
import { Col, Container, Row, Form } from 'react-bootstrap';

const CategoryCreations = ({ formData, setFormData }) => {
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col lg="12" className="py-3">
            <Form.Group>
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category Name"
                name="Category_type"
                value={formData?.Category_type || ''}
                onChange={handleChange}
              />
            </Form.Group>
          
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoryCreations;
