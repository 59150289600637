// import listing path 
import Login from "../view/Login"
import Products from '../view/master/Products'
import Customers from "../view/Customers"
import Invoice from "../view/Invoice"
import Unit from "../view/master/Unit";
import Category from '../view/master/Category'
// pdf
import Billpreview from '../pdf/BillPreview'
import InvoiceCreations from "../view/creations/InvoiceCreations";

import DailyLogins from "../view/Company";
import CustomersCreations from "../view/creations/CustomersCreations";
import User from "../view/master/User"




const routes = [
    
    //  login route
  { path: "/", element: <Login /> },
    // Page Routes litsing
  { path: "/company", element: <DailyLogins /> },
  { path: "/master/unit", element: <Unit /> },
  { path: "/master/category", element: <Category/> },
  { path: "/master/user", element: <User/> },
  { path: "/master/products", element: <Products/> },
  { path: "/products", element: <Products /> },
  { path: "/customers", element: <Customers /> },
  { path: "/invoices", element: <Invoice /> },  
  { path: "/billpreview", element: <Billpreview /> },

  // page routes creations
  { path: "/create-invoice", element: <InvoiceCreations /> },
  { path: "/create-customer", element: <CustomersCreations /> },
 

];
export default routes;