import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchCategoryApi,
  addCategoryApi,
  updateCategoryApi,
  deleteCategoryApi,
} from "../services/categoryService";

// Fetch all units
export const fetchCategory = createAsyncThunk("category/fetchCategory", async () => {
  const response = await fetchCategoryApi();
  console.log("Fetched Category from API:", response);
  return response; // Ensure this returns a valid array
});

// Add a new unit
export const createCategory = createAsyncThunk("category/createcategory", async (categoryData) => {
  const response = await addCategoryApi(categoryData);
  console.log("Create category API response:", response);
  return response; 
});

// Update an existing unit
export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, Category_type }) => {
    console.log("Sending data for update:", { id, Category_type });
    const response = await updateCategoryApi(id, Category_type);
    console.log("Update category API response:", response);
    return {
        id,
        Category_type,
      };
  }
  
);

// Delete a unit
export const deleteCategory = createAsyncThunk("category/deleteCategory", async (id) => {
  const response = await deleteCategoryApi(id);
  console.log("Delete API response:", response);
  return {
    id
  };
});

const categorySlice = createSlice({
  name: "category",
  initialState: {
    category: [], // Initial empty array
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch units
      .addCase(fetchCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("Fetched units payload:", action.payload);

        // Ensure payload is an array
        if (Array.isArray(action.payload)) {
          state.category = action.payload;
        } else {
          console.error("Expected payload to be an array, received:", action.payload);
        }
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Create unit
      .addCase(createCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        
        console.log("Create category response payload:", action.payload);
      
        // Ensure the payload is not empty and it's the expected array
        if (action.payload && action.payload.length > 0) {
          state.category.push(action.payload[0]); // Only push the first unit into the state
        } else {
          console.error("Unexpected payload structure in createCategory response:", action.payload);
        }
      })
      
      .addCase(createCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Update unit
      .addCase(updateCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("Update payload:", action.payload);
        console.log("Current state.category:", state.unit);
      
        const index = state.category.findIndex((category) => category.id === action.payload.id);
        console.log("Found index:", index);
      
        if (index !== -1) {
          console.log("Updating category type...");
          state.category[index].Category_type = action.payload.Category_type;
          console.log("Updated category:", state.category[index]);
        } else {
          console.error("category not found to update, adding new category...");
          state.unit.push(action.payload);
        }
      })
      
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // Delete unit
      .addCase(deleteCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.category = state.category.filter((category) => category.id !== action.payload.id);
        console.log("Remaining category after delete:", state.category);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default categorySlice.reducer;
