import {
  HiOutlineUserAdd,
  HiOutlineUser,
  HiOutlineNewspaper,
  HiOutlineLogin,
} from "react-icons/hi";
const MenuItems = [

  {
    path: "/company",
    icon: <HiOutlineLogin />,
    text: "Company",
  },
  {
    path: "/master",
    icon: <HiOutlineUserAdd />,
    text: "Master",
    submenu: [
      {
        path: "/master/unit",
        text: "Unit",
      },
      {
        path: "/master/category",
        text: "Category",
      },
      {
        path: "/master/user",
        text: "User",
      },
      {
        path: "/master/products",
        text: "Products",
      },
    ],
  },
  {
    path: "/customers",
    icon: <HiOutlineUser />,
    text: "Customers",
  },

  {
    path: "/invoices",
    icon: <HiOutlineNewspaper />,
    text: "Invoices",
  },
];

export default MenuItems;
