import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import { ActionButton, Buttons } from '../../components/Buttons';
import { TextInputForm } from '../../components/Forms';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import TableUI from '../../components/TableUI';
import CustomModal from '../../components/Modal';
import { MdOutlineDelete } from 'react-icons/md';
import { TbCircleLetterI } from 'react-icons/tb';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import CategoryCreations from './CategoryCreations';
import Pagnation from '../../components/Pagnation'
import {
  fetchCategory,
  createCategory,
  updateCategory,
  deleteCategory,
} from '../../slices/categorySlice';
import NotifyData from '../../components/NotifyData';

const Category = () => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.category);

  const [formData, setFormData] = useState({ Category_type: '' });

  console.log(formData);
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const handleEdit = (category) => {
    setEditMode(true);
    setSelectedCategory(category);
    setFormData({ Category_type: category.Category_type });
    handleOpen();
  };

  const handleCreate = () => {
    setEditMode(false);
    setFormData({ Category_type: '' });
    handleOpen();
  };

  const handleSubmit = async () => {
    if (editMode) {
      await dispatch(updateCategory({ id: selectedCategory.id, Category_type: formData.Category_type }));
      NotifyData('Category updated successfully', 'success');
    } else {
      await dispatch(createCategory(formData));
      NotifyData('Category created successfully', 'success');
    }
    handleClose();
    setFormData({ name: '' });
    setEditMode(false);
  };

  const handleDelete = async (id) => {
    await dispatch(deleteCategory(id));
    NotifyData('Category deleted successfully', 'success');
  };

  const filteredCategories = category?.filter((item) =>
    item.Category_type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const RoleHead = ['No', 'Category'];
  const RoleData = filteredCategories?.map((item, index) => ({
    values: [
      index + 1,
      item.Category_type,
      <ActionButton
        options={[
          { label: 'Edit', icon: <TbCircleLetterI />, onClick: () => handleEdit(item) },
          { label: 'Delete', icon: <MdOutlineDelete />, onClick: () => handleDelete(item.id) },
        ]}
        label={<HiOutlineDotsVertical />}
      />,
    ],
  }));

  return (
    <div id="main">
      <Container fluid>
        <Row>
          <Col lg="6" className="py-3">
            <PageTitle PageTitle="Categories" showButton={false} />
          </Col>
          <Col lg="6" className="py-3 text-end">
            <Buttons label="Create New" classname="crud-btn" OnClick={handleCreate} />
          </Col>
          <Col lg="3" className="align-self-center">
            <TextInputForm
              prefix_icon={<FaMagnifyingGlass />}
              PlaceHolder="Search Category"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control-padleft"
            />
          </Col>
          <Col lg="12" xs="12">
            <TableUI headers={RoleHead} body={RoleData} className="table-end" />
          </Col>
          <Col lg="12" xs="12" className='text-end'>
            <Pagnation/>
          </Col>
        </Row>
      </Container>
      <CustomModal
        show={show}
        setShow={setShow}
        pageTitle={editMode ? 'Edit Category' : 'Create Category'}
        showButton={true}
        submitButton={true}
        label={editMode ? 'Update' : 'Submit'}
        CancelLabel="Cancel"
        BodyComponent={<CategoryCreations formData={formData} setFormData={setFormData} />}
        OnClick={handleSubmit}
        Size="md"
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Category;
