import React, { useEffect } from "react";
import DropDown, { TextInputForm } from "../../components/Forms";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnit } from '../../slices/unitSlice';
import {
  fetchCategory
} from '../../slices/categorySlice';

const ProductsCreations = ({ formData, setFormData }) => {
  const dispatch = useDispatch();
  const { unit } = useSelector((state) => state.unit);
  const { category } = useSelector((state) => state.category);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchUnit());
  }, [dispatch]);
  return (
    <Container>
      <Row>
        <Col xs={12} className="py-3">
          <DropDown
            options={category.map((category) => ({
              label: category.Category_type,
              value: category.Category_type,
            }))}
            PlaceHolder="Category Name"
            textlabel="Category Name"
            name="Category_type"
            value={formData.Category_type || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={12} className="py-3">
          <TextInputForm
            PlaceHolder="Product Name"
            textlabel="Product Name"
            name="product_name"
            value={formData.product_name || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={6} className="py-3">
          <DropDown
            options={unit.map((unit) => ({
              label: unit.unit_type,
              value: unit.unit_type,
            }))}
            PlaceHolder="Unit"
            textlabel="Unit"
            name="Unit_type"
            value={formData.Unit_type || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={6} className="py-3">
          <DropDown
            options={unit.map((unit) => ({
              label: unit.unit_type,
              value: unit.unit_type,
            }))}
            PlaceHolder="SubUnit"
            textlabel="SubUnit"
            name="SubUnit_type"
            value={formData.SubUnit_type || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={6} className="py-3">
          <TextInputForm
            PlaceHolder="Count"
            textlabel="Count"
            name="Count"
            value={formData.Count || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={6} className="py-3">
          <TextInputForm
            PlaceHolder="Sub Count"
            textlabel="Sub Count"
            name="Sub_count"
            value={formData.Sub_count || ""}
            onChange={handleChange}
          />
        </Col>
        <Col xs={12} className="py-3">
          <TextInputForm
            PlaceHolder="Product Unit Price"
            textlabel="Product Unit Price"
            name="unit_price"
            value={formData.unit_price || ""}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductsCreations;
