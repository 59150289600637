import axiosInstance from "../config/API";
const API_ENDPOINT = '/product.php';

// Fetch all users
export const fetchProductApi = async (searchText = "") => {
  const payload ={
    action : "listProduct"
  }
  const response = await axiosInstance.post(API_ENDPOINT,payload);
  console.log("fetch product :" , response.data.body.products)
  return response.data.body.products;
};

// Add a new user
export const addProductApi = async (productData) => {
  const payload = {
    action: "createProduct",
    Category_type: productData.Category_type,
    product_name : productData.product_name,
    Unit_type : productData.Unit_type,
    SubUnit_type : productData.SubUnit_type,
    Count : productData.Count,
    Sub_count : productData.Sub_count,
    unit_price : productData.unit_price
  }
  const response = await axiosInstance.post(API_ENDPOINT, payload);
  console.log("add product :" ,response.data.products)
  return response.data.products;  
};

// Update a user by ID
export const updateProductApi = async (productdata) => {
  const payload = {
   action: "updateProductInfo",
   edit_Product_id : productdata.id,
   Category_type : productdata.Category_type,
   product_name : productdata.product_name,
   Unit_type : productdata.Unit_type,
   SubUnit_type : productdata.SubUnit_type,
   Count : productdata.Count,
   Sub_count : productdata.Sub_count,
   unit_price : productdata.unit_price
  }
  const response = await axiosInstance.post(`${API_ENDPOINT}`, payload);
  console.log('update response',response.data.head);
  return response.data.id;  // Corrected response structure
};

// Delete a user by ID
export const deleteProductApi = async (id) => {
  const payload = {
    action: "deleteProduct",
    delete_Product_id : id
  }
  const response = await axiosInstance.post(`${API_ENDPOINT}`,payload);
  return id;  // Return the user ID for successful deletion
};
